import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { style: {"padding":"12px 8px 10px 8px"} }
const _hoisted_2 = { class: "cp-map" }
const _hoisted_3 = { class: "cp-margin__tb--sm cp-padding__lr cp-form-btn-actions__wrapper cp-form__row" }
const _hoisted_4 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_input = _resolveComponent("form-input")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_app_row = _resolveComponent("app-row")!
  const _component_form_autocomplete = _resolveComponent("form-autocomplete")!
  const _component_gui_form_checkbox = _resolveComponent("gui-form-checkbox")!
  const _component_form_textarea = _resolveComponent("form-textarea")!
  const _component_form_multi_select = _resolveComponent("form-multi-select")!
  const _component_app_fieldset = _resolveComponent("app-fieldset")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_btn = _resolveComponent("btn")!
  const _component_form_button = _resolveComponent("form-button")!

  return (_openBlock(), _createElementBlock("form", {
    ref: "form",
    autocomplete: "off",
    onSubmit: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"]))
  }, [
    _createVNode(_component_app_row, { class: "cp-form-input-block" }, {
      default: _withCtx(() => [
        _createVNode(_component_app_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_app_fieldset, { class: "cp-height--full" }, {
              title: _withCtx(() => [
                _createTextVNode("Сведения о центре")
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_app_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_app_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_form_input, {
                            modelValue: _ctx.name,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                            label: "Название",
                            required: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_app_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_app_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_form_autocomplete, {
                            modelValue: _ctx.address,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.address) = $event)),
                            label: "Адрес",
                            class: "cp-margin__b--small-14",
                            "no-choise": "",
                            required: "",
                            "hide-empty-value": "",
                            loading: _ctx.addressLoading,
                            items: _ctx.addressesItems,
                            "react-model": true,
                            "min-length": 3,
                            onSearch: _ctx.searchAddress,
                            onChange: _ctx.changeSelectedAddress,
                            onSelect: _ctx.updateSelectedAddress
                          }, null, 8, ["modelValue", "loading", "items", "onSearch", "onChange", "onSelect"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_app_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_app_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_form_input, {
                            modelValue: _ctx.phone,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.phone) = $event)),
                            modifyValue: _ctx.phoneModify,
                            "onUpdate:modifyValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.phoneModify) = $event)),
                            label: "Контактный номер",
                            type: "phone"
                          }, null, 8, ["modelValue", "modifyValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_app_col, { class: "cp-margin__l--s" }, {
                        default: _withCtx(() => [
                          (_ctx.typeAction === 'edit')
                            ? (_openBlock(), _createBlock(_component_form_input, {
                                key: 0,
                                modelValue: _ctx.cityKladrId,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.cityKladrId) = $event)),
                                label: "Код КЛАДР города",
                                readonly: ""
                              }, null, 8, ["modelValue"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_app_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_app_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_gui_form_checkbox, {
                            modelValue: _ctx.isActive,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isActive) = $event)),
                            class: "cp-margin__l--7 cp-margin__b--15 cp-margin__t--16",
                            label: "Активность"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_app_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_app_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_form_textarea, {
                            modelValue: _ctx.description,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.description) = $event)),
                            label: "Описание",
                            class: "cp-textarea__line textarea_fix_width cp-margin__b--small-14"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_app_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_app_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_form_multi_select, {
                            modelValue: _ctx.competence,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.competence) = $event)),
                            modifyValue: _ctx.competenceModify,
                            "onUpdate:modifyValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.competenceModify) = $event)),
                            label: "Список компетенций",
                            items: _ctx.competenceItems,
                            "data-name": "competences",
                            class: "max-width--none"
                          }, null, 8, ["modelValue", "modifyValue", "items"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_app_col, { class: "cp-margin__l--s" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (!_ctx.isLoadingInfo)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    id: "map",
                    class: "cp-map__wrapper cp-br-10",
                    onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.clickMap && _ctx.clickMap(...args)))
                  }))
                : _createCommentVNode("", true),
              (_ctx.isMapInit && _ctx.typeAction === 'edit')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.isBlock)
                      ? (_openBlock(), _createBlock(_component_btn, {
                          key: 0,
                          size: "small",
                          type: "button",
                          variant: "icon",
                          class: "cp-map-button cp-map-button--edit",
                          onClick: _ctx.editCoordinate
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_icon_font, {
                              class: "cp-map-button__icon",
                              icon: "edit-location",
                              size: "18"
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createVNode(_component_btn, {
                            size: "small",
                            type: "button",
                            variant: "icon",
                            class: "cp-map-button cp-map-button--save",
                            onClick: _ctx.saveCoordinate
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_icon_font, {
                                class: "cp-map-button__icon",
                                icon: "check-mark",
                                size: "16"
                              })
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_btn, {
                            size: "small",
                            type: "button",
                            variant: "icon",
                            class: "cp-map-button cp-map-button--cancel",
                            onClick: _ctx.cancelCoordinate
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_icon_font, {
                                class: "cp-map-button__icon",
                                icon: "close",
                                size: "16"
                              })
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ], 64)),
                    _withDirectives(_createElementVNode("div", {
                      class: "cp-map-tooltip",
                      ref: "tooltip",
                      style: _normalizeStyle(_ctx.tooltipPosition)
                    }, " Для редактирования метки нажмите на иконку в левом верхнем углу ", 4), [
                      [_vShow, _ctx.isShowTooltip]
                    ])
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_form_button, {
          text: _ctx.text.btnSave,
          type: 'submit'
        }, null, 8, ["text"]),
        _createVNode(_component_form_button, {
          text: _ctx.text.btnCancel,
          bordered: true,
          class: "cp-margin__l--12",
          onBtnClick: _ctx.returnBefore
        }, null, 8, ["text", "onBtnClick"])
      ]),
      (_ctx.$route.name === 'training_center_edit')
        ? (_openBlock(), _createBlock(_component_form_button, {
            key: 0,
            text: _ctx.text.btnDelete,
            bordered: true,
            onBtnClick: _ctx.deleteTrainingCenter
          }, null, 8, ["text", "onBtnClick"]))
        : _createCommentVNode("", true)
    ])
  ], 544))
}