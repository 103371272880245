
import { Component, Prop, Watch } from '@/lib/decorator';

import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import FormInput from '@/components/form/Input/index.vue';
import FormMultiSelect from '@/components/form/MultiSelect/index.vue';
import FormTextarea from '@/components/form/Textarea/index.vue';
import IconFont from '@/components/icons/IconFont.vue';
import Btn from '@/components/buttons/Btn/Btn.vue';
import FormAutocomplete from '@/components/form/Autocomplete/index.vue';
import FormButton from '@/components/form/Button/index.vue';

import trainingCenterModule from '@/store/settings/trainingCenter';
import trainingCenterEntityModule from '@/store/settings/trainingCenter/entity';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import { searchAddress } from '@/api/geo';
import { getAllCompetenceList } from '@/api/competence';

import { strings } from '@/lib/stringConst';
import YandexMap from '@/lib/map/yandexMap';
import FormEditor from '@/lib/FormEditor';
import { GuiFormCheckbox } from '@library/gigant_ui';

let YANDEX_MAP: YandexMap = Object.create(null);
@Component({
  components: {
    GuiFormCheckbox,
    FormButton,
    FormAutocomplete,
    Btn,
    IconFont,
    FormTextarea,
    FormMultiSelect,
    FormInput,
    AppCol,
    AppRow,
    AppFieldset,
  },
  emits: ['save', 'returnBefore', 'click'],
})
export default class TrainingCenterForm extends FormEditor {
  @Prop() typeAction!: string;
  @Prop() view!: string;

  name = this.model.name ?? '';

  address = this.model.address ?? { id: null, value: '' };
  addressesItems: { id: string; value: string; latitude: number; longitude: number }[] = [];
  addressLoading = false;

  phone = this.model.phone ?? '';
  phoneModify = '';

  isActive = this.model.isActive ?? 0;
  cityKladrId = this.model.cityKladrId ?? '';
  description = this.model.description ?? '';

  competence = this.model.competence.length ? this.model.competence : [];
  competenceItems: { id: string; value: string; name: string }[] = [];
  competenceModify: Record<string, string> | null = null;

  newCoordinates = trainingCenterEntityModule.model.addressCoordinates.default;
  tooltipPosition = {};
  idTimeout: number | null = null;
  isShowTooltip = false;
  isMapInit = false;
  isBlock = true;

  text = strings;

  canSaveNewAddressString = false;

  get isBlockMap(): boolean {
    return YANDEX_MAP.isBlock;
  }

  set isBlockMap(value: boolean) {
    this.isBlock = value;
    YANDEX_MAP.isBlock = value;
  }

  get isLoadingInfo() {
    return trainingCenterEntityModule.isLoading;
  }

  get model() {
    return trainingCenterEntityModule.model;
  }

  get coordinates(): { latitude: number; longitude: number } {
    return trainingCenterEntityModule.model.addressCoordinates.value as { latitude: number; longitude: number };
  }

  get textFieldsData() {
    const data = {
      name: this.name,
      address: this.address.value,
      phone: this.phoneModify,
      isActive: this.isActive,
      description: this.description,
      ...(this.competenceModify as Record<string, string>),
    };

    return Object.assign({}, data);
  }

  get selectedAddress() {
    return trainingCenterEntityModule.selectedAddress;
  }

  @Watch('selectedAddress')
  async addressStringChangedInModel(newValue: { address: string; latitude: number; longitude: number }) {
    if (newValue.address !== '' && this.canSaveNewAddressString) {
      this.address = { id: newValue.address, value: newValue.address };
      this.addressesItems = [
        {
          id: newValue.address,
          value: newValue.address,
          latitude: newValue.latitude,
          longitude: newValue.longitude,
        },
      ];

      this.updateSelectedAddress({
        value: newValue.address,
        data: {
          latitude: newValue.latitude,
          longitude: newValue.longitude,
        },
      });
    }
  }

  async getAllCompetence() {
    try {
      const result = await getAllCompetenceList();

      this.competenceItems = result.map((competence: { id: string; name: string }) => {
        return {
          id: competence.id,
          value: competence.name,
          name: competence.name,
        };
      });
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  deleteTrainingCenter() {
    trainingCenterModule.deleteModal(this.$route.params.trainingCenterId as string);
  }

  async initMap() {
    const mapWrapper = document.getElementById('map');

    if (!this.coordinates) {
      return false;
    }

    if (!mapWrapper) {
      setTimeout(() => {
        this.initMap();
      }, 300);

      return false;
    }

    const height = mapWrapper && mapWrapper.clientHeight >= 500 ? mapWrapper.clientHeight : 500;
    mapWrapper.style.height = `${height}px`;

    YANDEX_MAP = new YandexMap();
    YANDEX_MAP.initMap(this.coordinates, 'map');
    if (this.coordinates.latitude && this.coordinates.longitude) {
      await YANDEX_MAP.addPlaceMarker(this.coordinates);
    }

    this.isBlockMap = this.typeAction === 'edit';
    YANDEX_MAP.addEventsClick();

    this.isMapInit = true;
  }

  clickMap(e: { layerY: string; layerX: string }) {
    if (this.typeAction === 'add' || !this.isBlockMap) {
      return;
    }

    this.showTooltip();
    this.tooltipPosition = {
      top: e.layerY + 'px',
      left: e.layerX + 'px',
    };
  }

  clearTimeout(id: number) {
    if (id) {
      clearTimeout(id);
    }
  }

  hideTooltip() {
    this.clearTimeout(this.idTimeout as number);

    this.isShowTooltip = false;
  }

  showTooltip() {
    this.clearTimeout(this.idTimeout as number);

    this.isShowTooltip = true;
    this.idTimeout = (setTimeout(() => {
      this.isShowTooltip = false;
    }, 3000) as unknown) as number;
  }

  toggleBlockMap() {
    this.isBlockMap = !this.isBlock;
  }

  editCoordinate() {
    this.hideTooltip();
    this.toggleBlockMap();
  }

  saveCoordinate() {
    const newCoordinates = this.newCoordinates;
    this.updateCoordinates(newCoordinates);
    this.toggleBlockMap();
  }

  cancelCoordinate() {
    const oldCoordinates = this.coordinates;
    YANDEX_MAP.updatePlaceMarker([oldCoordinates.latitude, oldCoordinates.longitude]);
    this.toggleBlockMap();
  }

  returnBefore() {
    this.$emit('returnBefore');
  }

  changeSelectedAddress(e: { target: { value: string } }) {
    this.updateSelectedAddress({ value: e.target.value });
  }

  async updateSelectedAddress(selected: { value: string; data?: { latitude: number; longitude: number } }) {
    if (!selected.value.trim().length) {
      return false;
    }

    if (typeof selected.data !== 'undefined') {
      this.updatePointMap(selected.value, {
        lat: selected.data.latitude,
        lng: selected.data.longitude,
      });
      trainingCenterEntityModule.updateAddressCoordinates({
        latitude: selected.data.latitude,
        longitude: selected.data.longitude,
      });
    }
  }

  updatePointMap(address: string, coordinates: { lat: number; lng: number }) {
    YANDEX_MAP.updatePlaceMarker([coordinates.lat, coordinates.lng]);
    YANDEX_MAP.entity.setCenter([coordinates.lat, coordinates.lng]);
    YANDEX_MAP.setPlaceMarkAddress(address);
  }

  updateCoordinates(coordinates: { latitude: number; longitude: number }) {
    this.canSaveNewAddressString = true;
    this.newCoordinates = { latitude: coordinates.latitude, longitude: coordinates.longitude };
    trainingCenterEntityModule.updateAddressCoordinates(this.newCoordinates);
    trainingCenterEntityModule.updateSelectedAddress(this.newCoordinates);
  }

  setNewCoordinate(event: any) {
    this.canSaveNewAddressString = false;
    this.newCoordinates = { latitude: event.detail[0], longitude: event.detail[1] };
  }

  prepareResultAddressItems(addresses: { address: string; lat: number; long: number }[]) {
    return addresses.map((address: { address: string; lat: number; long: number }) => {
      return {
        id: address.address,
        value: address.address,
        latitude: address.lat,
        longitude: address.long,
      };
    });
  }

  async searchAddress(value: string) {
    try {
      if (value.length === 0) {
        this.addressesItems = [];

        return;
      }

      this.addressLoading = true;

      const result = await searchAddress(value);

      this.addressesItems = this.prepareResultAddressItems(result);

      this.addressLoading = false;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  async add() {
    try {
      const result =
        this.view === 'customer'
          ? await trainingCenterEntityModule.addNew({
              customerId: this.$route.params.customerId as string,
              textFieldsData: this.textFieldsData,
            })
          : await trainingCenterEntityModule.addNew({ textFieldsData: this.textFieldsData });

      if (result && !result.message) {
        this.returnBefore();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async edit() {
    try {
      await trainingCenterEntityModule.saveUpdate({
        id: this.$route.params.trainingCenterId as string,
        textFieldsData: this.textFieldsData,
      });
    } catch (error) {
      console.error(error);
    }
  }

  created() {
    document.body.addEventListener(
      'yandexMapClick',
      this.typeAction === 'edit'
        ? this.setNewCoordinate
        : (event: any) => {
            this.updateCoordinates({ latitude: event.detail[0], longitude: event.detail[1] });
          }
    );
  }

  async mounted() {
    await this.initMap();

    await this.getAllCompetence();
  }
}
